import {Component, OnInit} from '@angular/core';
import {ComplaintService} from '../../data/services/complaint.service';
import {HupSubscriptionsService} from '../../data/services/hup-subscriptions.service';
import {TranslationService} from '../../core/services/translation.service';
import {Complaint} from '../../data/models/complaint.model';
import {HupSubscription} from '../../data/models/subscription.model';
import {ActivatedRoute} from '@angular/router';
import {BaseDataService} from '../../core/services/base-data.service';
import {NavigationCommand, NavigationService} from "../../data/services/navigation.service";

@Component({
  selector: 'app-complaint',
  templateUrl: './complaint.component.html',
  styleUrls: ['./complaint.component.scss']
})
export class ComplaintComponent implements OnInit {
  isLoadingComp: boolean = false;
  isLoadingSub: boolean = false;
  complaints: Complaint[] = [];
  complaintsGrouped: Complaint[][] = [];
  subs: HupSubscription[] = [];
  errorsArr: string[] = [];

  constructor(private complaintService: ComplaintService,
              private hupSubscriptionsService: HupSubscriptionsService,
              private navigationService: NavigationService,
              private route: ActivatedRoute,
              public translationService: TranslationService,
              private baseDataService: BaseDataService
  ) {
  }

  ngOnInit(): void {
    this.isLoadingComp = true;
    this.isLoadingSub = true;
    this.complaintService.getComplaints().subscribe(complaints => {
      this.isLoadingComp = false;
      complaints.forEach((comp: Complaint) => {
        this.baseDataService.handleComplaint(comp);
        let isGrouped = false;
        this.complaintsGrouped.forEach((compGroup: Complaint[]) => {
          if (!isGrouped && compGroup.length > 0) {
            let compToCompare = compGroup.at(0);
            isGrouped = compToCompare.subId == comp.subId && compToCompare.memo.memoText == comp.memo.memoText &&
              compToCompare.reason == comp.reason;
            if (isGrouped) {
              compGroup.push(comp);
              compGroup.sort((a, b) => {
                return a.validDate.validFrom < b.validDate.validFrom ? -1 : 1
              });
            }
          }
        });
        if (!isGrouped) {
          this.complaintsGrouped.push(new Array(comp));
        }
      });
      this.complaints = complaints;
    }, error => {
      this.isLoadingComp = false;
      this.errorsArr.push(this.translationService.getTranslation('error_complaints_init'));
    });

    this.hupSubscriptionsService.getSubscriptions().subscribe(subs => {
      this.isLoadingSub = false;
      this.subs = [];
      subs.forEach(sub => {
        if ((sub.validDate.validUntil == null || sub.validDate.validUntil > new Date()) && sub.backendId > 0) {
          this.subs.push(sub);
        }
      });
    }, error => {
      this.isLoadingSub = false;
      this.errorsArr.push(this.translationService.getTranslation('error_subscription_init'));
    });

    this.complaintService.consumeErrorMessages().subscribe(
      msgs => msgs.forEach(
        msg => this.errorsArr.push(msg)
      )
    );
  }

  onNewComplaint(sub: HupSubscription): void {
    //this.redirectService.redirect('/complaint/new/' + sub.webId + '/' + +sub.backendId + '/false', true);
    this.navigationService.navigateTo(NavigationCommand.COMPLAINTNEW, [sub.webId + '', sub.backendId + '', 'false', 'true'])
  }

  onDetails(index: number): void {
    let isPrint = true;
    this.subs.forEach(sub => {
      const complaint = this.complaints[index];
      if (sub.backendId === complaint.subId) {
        isPrint = !sub.offer.offerType.ePaper;
      }
    });
    this.navigationService.navigateTo(NavigationCommand.COMPLAINTEDIT, [this.complaints[index].webId + '', this.complaints[index].backendId + '', 'false', isPrint ? 'true' : 'false']);

  }

  onDelete(index: number): void {
    alert('TODO: löschen');
  }

  onEdit(index: number): void {
    let isPrint = true;
    this.subs.forEach(sub => {
      const complaint = this.complaints[index];
      if (sub.backendId === complaint.subId) {
        isPrint = !sub.offer.offerType.ePaper;
      }
    });
    this.navigationService.navigateTo(NavigationCommand.COMPLAINTEDIT, [this.complaints[index].webId + '', this.complaints[index].backendId + '', 'false', isPrint ? 'true' : 'false']);
  }
}
