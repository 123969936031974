import {Directive, HostBinding, Input} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appSliderTextStyle]'
})
export class SliderTextStyleDirective {
  @Input('appTextStyleAlign') @HostBinding('style.text-align') textAlign?: string;
  @Input('appTextStyleColor') @HostBinding('style.color') color?: string;
  @Input('appTextStyleSize') @HostBinding('style.font-size') fontSize?: string;
  @Input('appTextStyleFamily') @HostBinding('style.font-family') fontFamily?: string;

  constructor() {
  }
}
