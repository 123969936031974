import {Component, OnInit} from '@angular/core';
import {TranslationService} from '../../../core/services/translation.service';

@Component({
  selector: 'app-complaint-without-login-after',
  templateUrl: './complaint-without-login-after.component.html',
  standalone: true,
  imports: [  ],
  styleUrls: ['./complaint-without-login-after.component.css']
})
export class ComplaintWithoutLoginAfterComponent {
  constructor(public translationService: TranslationService) {
  }
}
