import {Component, Input} from '@angular/core';
import {NavigationService} from 'src/app/data/services/navigation.service';
import {ExternalOffer} from '../../data/models/external-offer.model';
import {ConfigService} from "../../core/services/config.service";

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.css']

})
export class ImageSliderComponent {
  @Input() externalOffers: ExternalOffer[] = [];

  public configNameShowOfferData = 'slider_show_external_offer_data';
  public configKeyTextAlign: string = 'slider_external_offer_data_position';
  public configKeyTextColor: string = 'slider_external_offer_data_color';
  public configKeyFontSize: string = 'slider_external_offer_data_size';
  public configKeyTextFontFamily: string = 'slider_external_offer_data_font_family';

  public showOfferData: boolean = false;
  public textAlignValue: string = 'center';
  public textColorValue: string = '#FFF';
  public textFontSize: string = '20';
  public textFontFamily: string = 'Roboto';

  private errorMessage = 'Error while loading config for ';

  constructor(public navigationService: NavigationService,
              public configService: ConfigService,
              ) {
    this.externalOffers = this.externalOffers.filter(offer => offer.imageUrl != null && offer.hasImage);

    this.configService.loadConfig(this.configNameShowOfferData)
      .subscribe({
        next: value => {
          if (value?.value) {
            if (value.value === 'true' || value.value === '1') {
              this.showOfferData = true;
            }
          }
        },
        error: err => console.error(this.errorMessage  +  'displaying header & description of the external offer.', err),
      });

    this.configService.loadConfig(this.configKeyTextAlign)
      .subscribe({
        next: value => {
          if (value?.value) {
            this.textAlignValue = value.value;
          }
        },
        error: err => console.error(this.errorMessage + 'text-align.', err),
      });

    this.configService.loadConfig(this.configKeyTextColor)
      .subscribe({
        next: value => {
          if (value?.value) {
            this.textColorValue = value.value;
          }
        },
        error: err => console.error(this.errorMessage + 'the text-color.', err),
      });

    this.configService.loadConfig(this.configKeyFontSize)
      .subscribe({
        next: value => {
          if (value?.value) {
            this.textFontSize = value.value + 'px';
          }
        },
        error: err => console.error(this.errorMessage + 'font-size.', err),
      });

    this.configService.loadConfig(this.configKeyTextFontFamily)
      .subscribe({
        next: value => {
          if (value?.value) {
            this.textFontFamily = value.value;
          }
        },
        error: err => console.error(this.errorMessage + 'font-family.', err),
      });
  }
}
