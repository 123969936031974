<ngb-carousel>
  <ng-template ngbSlide *ngFor="let exOf of externalOffers">
    <div class="picsum-img-wrapper">
      <ng-container *ngIf="exOf?.imageUrl">
        <img alt="{{exOf?.header}}" [src]="exOf?.imageUrl" class="image"
             (click)="navigationService.navigateExtern(exOf?.externalURL)"/>
      </ng-container>
    </div>
    <div *ngIf="showOfferData" class="carousel-caption"
         (click)="navigationService.navigateExtern(exOf?.externalURL)">
      <p appSliderTextStyle
          [appTextStyleAlign]="textAlignValue"
          [appTextStyleColor]="textColorValue"
          [appTextStyleSize]="textFontSize"
          [appTextStyleFamily]="textFontFamily"
          [innerHTML]="exOf?.header">
      </p>
      <p appSliderTextStyle
          [appTextStyleAlign]="textAlignValue"
          [appTextStyleColor]="textColorValue"
          [appTextStyleSize]="textFontSize"
          [appTextStyleFamily]="textFontFamily"
          [innerHTML]="exOf?.description">
      </p>
    </div>
  </ng-template>
</ngb-carousel>
