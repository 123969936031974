import {Component, OnInit} from '@angular/core';
import {TranslationService} from '../../core/services/translation.service';
import {PaymentService} from '../../data/services/payment.service';
import {HupSubscriptionsService} from '../../data/services/hup-subscriptions.service';
import {HupSubscription} from '../../data/models/subscription.model';
import {Payment} from '../../data/models/payment.model';
import {NavigationCommand, NavigationService} from "../../data/services/navigation.service";

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  isLoadingPayments: boolean = false;
  isLoadingSubs: boolean = false;
  editMode: boolean = false;
  errorsArr: string[] = [];

  subs: HupSubscription[];
  payments: Payment[];

  constructor(private paymentService: PaymentService,
              private hupSubscriptionsService: HupSubscriptionsService,
              private navigationService: NavigationService,
              public translationService: TranslationService,
  ) {
  }

  ngOnInit(): void {
    this.isLoadingSubs = true;

    this.hupSubscriptionsService.getSubscriptions().subscribe({
      next: (subs) => {
      this.isLoadingSubs = false;
      this.subs = subs;
    }, error: (error) => {
      this.isLoadingSubs = false;
      this.errorsArr.push(this.translationService.getTranslation('error_subscription_init'));
    }});

    this.paymentService.getPayments().subscribe({
      next: (payments) => {
        this.isLoadingPayments = false;
        this.payments = payments;
      },
      error: (error) => {
        this.isLoadingPayments = false;
        this.errorsArr.push(this.translationService.getTranslation('error_payment_init'));
      }
    });

  }

  subPaymentEdit(sub: HupSubscription): void {
    this.navigationService.navigateTo(NavigationCommand.SUBPAYMENTEDIT, [sub.webId+'', sub.backendId+''])
  }

  paymentEdit(payment: Payment): void {
    this.navigationService.navigateTo(NavigationCommand.PAYMENTEDIT, [payment.webId+'', payment.backendId+''])
  }

}
