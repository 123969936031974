<ng-container [formGroup]="controlGroup">
  <ng-container formGroupName = "address"
    *ngIf="controlGroup.get('address.country').value.key !== 'A' && controlGroup.get('address.country').value !== 'A'">
    <mat-form-field class="col-12 col-md-6 ps-2">
      <mat-label [innerHTML]="translationService.getTranslation('address_street')"></mat-label>
      <input matInput formControlName="street" required [matAutocomplete]="matStreetNames"
             (focusin)="searchAddressEmit.emit()">
      <mat-autocomplete #matStreetNames="matAutocomplete">
        <mat-option *ngFor="let street of streetNames" [value]="street">
          {{street}}
        </mat-option>
      </mat-autocomplete>
      <mat-error
        *ngIf="controlGroup.get('address.street').hasError('message')">{{controlGroup.get('address.street').getError('message')}}</mat-error>
    </mat-form-field>
    <mat-form-field class="col-12 col-md-6 ps-2">
      <mat-label [innerHTML]="translationService.getTranslation('address_houseno')"></mat-label>
      <input maxlength="10" matInput formControlName="houseNo" (keyup)="replaceInvalidCharactersFromHouseNo()" required #houseNumber>
      <app-signs-counter *ngIf="houseNumber.value.length > 0 && houseNumber.value.length <= houseNumber.maxLength"
                         [actual]="houseNumber.value.length" [possible]="houseNumber.maxLength"></app-signs-counter>
      <mat-error
        *ngIf="controlGroup.get('address.houseNo').hasError('message')">{{controlGroup.get('address.houseNo').getError('message')}}</mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container formGroupName = "address"
    *ngIf="controlGroup.get('address.country').value === 'A' || controlGroup.get('address.country').value.key === 'A'">
    <mat-form-field class="col-12 col-md-6 ps-2">
      <mat-label [innerHTML]="translationService.getTranslation('address_street')"></mat-label>
      <input matInput formControlName="street" required [matAutocomplete]="matStreetNames" (focusin)="searchAddressEmit.emit()">
      <mat-autocomplete #matStreetNames="matAutocomplete">
        <mat-option *ngFor="let street of streetNames" [value]="street">
          {{street}}
        </mat-option>
      </mat-autocomplete>
      <mat-error
        *ngIf="controlGroup.get('address.street').hasError('message')">{{controlGroup.get('address.street').getError('message')}}</mat-error>
    </mat-form-field>
    <mat-form-field class="col-3 ps-2">
      <mat-label [innerHTML]="translationService.getTranslation('address_houseno')"></mat-label>
      <input maxLength="10" matInput formControlName="houseNo" (keyup)="replaceInvalidCharactersFromHouseNo()" required #houseNumber>
      <app-signs-counter *ngIf="houseNumber.value.length > 0 && houseNumber.value.length <= houseNumber.maxLength" [actual]="houseNumber.value.length" [possible]="houseNumber.maxLength"></app-signs-counter>
      <mat-error
        *ngIf="controlGroup.get('address.houseNo').hasError('message')">{{controlGroup.get('address.houseNo').getError('message')}}</mat-error>
    </mat-form-field>
    <mat-form-field class="col-3 ps-2">
      <mat-label [innerHTML]="translationService.getTranslation('address_stiege')"></mat-label>
      <input maxLength="2" matInput formControlName="stiege" #stiege>
      <app-signs-counter *ngIf="stiege.value.length > 0 && stiege.value.length <= stiege.maxLength" [actual]="stiege.value.length" [possible]="stiege.maxLength"></app-signs-counter>
      <mat-error
        *ngIf="controlGroup.get('address.stiege').hasError('message')">{{controlGroup.get('address.stiege').getError('message')}}</mat-error>
    </mat-form-field>
    <mat-form-field class="col-3 ps-2">
      <mat-label [innerHTML]="translationService.getTranslation('address_stock')"></mat-label>
      <input maxLength="2" matInput formControlName="stock" #stock>
      <app-signs-counter *ngIf="stock.value.length > 0 && stock.value.length <= stock.maxLength" [actual]="stock.value.length" [possible]="stock.maxLength"></app-signs-counter>
      <mat-error
        *ngIf="controlGroup.get('address.stock').hasError('message')">{{controlGroup.get('stock').getError('message')}}</mat-error>
    </mat-form-field>
    <mat-form-field class="col-3 ps-2">
      <mat-label [innerHTML]="translationService.getTranslation('address_tuer')"></mat-label>
      <input maxLength="3" matInput formControlName="tuer" #door>
      <app-signs-counter *ngIf="door.value.length > 0 && door.value.length <= door.maxLength" [actual]="door.value.length" [possible]="door.maxLength"></app-signs-counter>
      <mat-error
        *ngIf="controlGroup.get('address.tuer').hasError('message')">{{controlGroup.get('address.tuer').getError('message')}}</mat-error>
    </mat-form-field>
  </ng-container>
</ng-container>
