import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {CssPropertiesEnum} from '../../core/models/css-properties.enum';
import {TranslationService} from '../../core/services/translation.service';
import {VHostService} from '../../core/services/v-host.service';
import {OfferGroup} from '../../data/models/offer-group.model';
import {OfferService} from '../../data/services/offer.service';
import {NavigationCommand, NavigationService} from '../../data/services/navigation.service';
import {DOCUMENT} from '@angular/common';
import {ExternalOfferService} from '../../core/services/external-offer.service';
import {ExternalOffer} from '../../data/models/external-offer.model';
import {ImageService} from '../../core/services/image.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ConfigService} from "../../core/services/config.service";

@Component({
  selector: 'app-offer-group',
  templateUrl: './offer-group.component.html',
  styleUrls: ['./offer-group.component.css']
})
export class OfferGroupComponent implements OnInit, AfterViewInit {

  CssPropertiesEnum = CssPropertiesEnum;
  isLoadingOfferGroup: boolean = false;
  errorsArr: string[] = [];
  offerGroups: OfferGroup[] = [];
  onlyOneGroup: boolean = false;
  cssPropertiesEnum = CssPropertiesEnum;
  public externalOffersForSlider: ExternalOffer[] = [];

  constructor(
    public translationService: TranslationService,
    public vHostService: VHostService,
    private offerService: OfferService,
    private navigationService: NavigationService,
    public externalOfferService: ExternalOfferService,
    public domSanitizer: DomSanitizer,
    public imageService: ImageService,
    @Inject(DOCUMENT) private document: Document,
    public configService: ConfigService
  ) {
    this.isLoadingOfferGroup = true;
    this.offerService.getOfferGroups().subscribe(offerGroups => {
      this.offerGroups = offerGroups;
      if (offerGroups.length == 1) {
        this.goToOfferModule(offerGroups[0].key, 0);
      } else {
        this.offerService.getMinimalOffers(true).subscribe(offers => {
          offerGroups.forEach(group => {
            group.hasOffers = offers.some(offer => offer.offerGroup === group.key);
            if (!group.hasOffers) {
              console.log('No offers for groupId ' + group.key);
            }
          });
          this.isLoadingOfferGroup = false;
        }, error => {
          this.errorsArr.push(this.translationService.getTranslation('error_offerGroup_init'));
        });
      }
    }, error => {
      this.errorsArr.push(this.translationService.getTranslation('error_offer_init'));
    });

  }

  ngOnInit(): void {
     this.externalOfferService.loadExternalOffersForSlider().subscribe(externalOffers => {
       if (externalOffers) {
         this.externalOffersForSlider = externalOffers;
         this.externalOffersForSlider.forEach(e => {
           this.imageService.getImageOrError(e.id).subscribe(image => {
             if (image && image.type && image.file) {
               e.imageUrl = this.domSanitizer.bypassSecurityTrustResourceUrl('data:' + image.type + ';base64,' + image.file);
               e.image = image;
               if (!e.imageUrl) {
                 externalOffers.splice(externalOffers.indexOf(e));
               }
             }
           });
         });
       }
     });
  }

  goToOfferModule(offerGroup, moreThanOneGroup): void {
    this.navigationService.navigateTo(NavigationCommand.OFFERLIST, [offerGroup, moreThanOneGroup]);
  }

  ngAfterViewInit(): void {
  }
}
