export function getHouseNo(houseNo: string, countryKey: string, stock?: string, stiege?: string, tuer?: string): string {
  if (countryKey === 'A' || countryKey === 'AT') {
    return '01#'.concat(houseNo)
      .concat('#').concat(stiege ? stiege : '')
      .concat('#').concat(stock ? stock : '')
      .concat('#').concat(tuer ? tuer : '');
  } else {
    return '00#'.concat(houseNo).concat('###');
  }
}
