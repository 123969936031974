import {Inject, Injectable, Optional} from '@angular/core';
import {RESPONSE} from "@nguniversal/express-engine/tokens";
import {Response} from "express";
import {Router} from "@angular/router";
import {RedirectService} from "./redirect.service";
import {ConfigService} from "../../core/services/config.service";
import {LandingPageService} from "./landing-page.service";
import {LandingPage} from "../models/landing-page.model";

export enum NavigationCommand {
  SUBSCRIPTION = '/subscription',
  OFFER = '/offer',
  OFFERLIST = '/offer/list',
  OFFERORDERED = '/offer/ordered',
  SUBCHANGE = '/sub-change',
  COMPLAINT = '/complaint',
  COMPLAINTNEW = '/complaint/new',
  COMPLAINTEDIT = '/complaint/edit',
  COMPLAINTWITHPUTLOGINNEW = '/complaint-without-login/new',
  COMPLAINTWITHPUTLOGINAFTER = '/complaint-without-login/complaint-without-login-after',
  PAYMENT = '/payment',
  SUBPAYMENTEDIT = '/payment/sub-payment-edit',
  PAYMENTEDIT = '/payment/payment-edit',
  SUBCHANGENEW = '/sub-change/new',
  SUBCHANGEEDIT = '/sub-change/edit',
  CONTACT = '/contact',
  CONTACTOVERVIEW = '/contact-overview'
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private landingPages: LandingPage[];

  constructor(
    private redirectService: RedirectService,
    private router: Router,
    private landingpageService: LandingPageService) {
    this.landingpageService.getLandingPages().subscribe(landingPages => {
      this.landingPages = landingPages;
    })
  }

  navigateTo(command: NavigationCommand,commandParams: string[] = null, backToOverview: boolean = false, ): Promise<boolean> {
    let url = command.toString();
    if (backToOverview) {
      let navigated: boolean = false;
      this.landingPages.every(landingPage => {
        if (landingPage.isExtern && url.startsWith(landingPage.link, 1)) {
          navigated = true;
          this.redirectService.redirect(landingPage.externLink, true);
          return false;
        }
      });
      if (!navigated) {
        return this.navigateIntern(url, commandParams);
      }
    } else {
      return this.navigateIntern(url, commandParams);
    }
  }

  private navigateIntern(url: string, commandParams: string[] = null): Promise<boolean> {
    commandParams?.forEach(param => {
      url = url + '/' + param;
    });
    return this.router.navigate([url], {queryParamsHandling: "preserve"});
  }

  navigateExtern(url: string) {
    return this.router.navigate([]).then(r => window.open('https://' + url));
  }
}
