<div class="row" id="complaintSubscriptionHeader">
  <div class="col-12">
    <h4 [innerHTML]="translationService.getTranslation('complaint_intro')"></h4>
  </div>
  <div class="col-12" [innerHTML]="translationService.getTranslation('complaint_description')">
  </div>
</div>
<div class="row">
  <div class="col-12 complaintSubscriptionErrors">
    <app-general-errors [errors]="errorsArr"></app-general-errors>
    <div *ngIf="isLoadingSub || isLoadingComp" style="text-align: center;" id="complaintSubscriptionSpinner">
      <app-loading-spinner></app-loading-spinner>
    </div>
    <div *ngIf="!isLoadingSub && !isLoadingComp && subs.length == 0 && errorsArr.length == 0" [innerHTML]="translationService.getTranslation('intro_noSubs')">
    </div>
    <div id="complaintSubscriptions">
    <ng-container *ngIf="!isLoadingComp && !isLoadingSub && subs.length > 0">
      <mat-card appearance="outlined" *ngFor="let sub of subs; let i = index" class="mt-3">
        <div class="row m-3">
          <div class="col-12 col-md-6">
            <mat-card-title>{{sub.productDescription}}</mat-card-title>
            <mat-card-subtitle *ngIf="sub.subId" [innerHTML]="translationService.getTranslation('order_id') + ': ' + sub.subId">
            </mat-card-subtitle>
            <mat-card-subtitle *ngIf="sub.variantDescription" [innerHTML]="translationService.getTranslation('order_edition') + ': ' + sub.variantDescription">
            </mat-card-subtitle>
            <mat-card-subtitle *ngIf="sub.validDate" [innerHTML]="translationService.getTranslation('period') +': ' +
              (sub.validDate.validFrom | date: translationService.getTranslation('dateFormat')) + ' - ' +
              ((sub.validDate.validUntil) ? (sub.validDate.validUntil | date: translationService.getTranslation('dateFormat')) : translationService.getTranslation('subscription_endless'))">
            </mat-card-subtitle>
            <mat-card-subtitle *ngIf="sub.curDelivery">
              {{sub.curDelivery.person.salutationDescription}}
              {{sub.curDelivery.person.firstname}}
              {{sub.curDelivery.person.lastname}},
              {{sub.curDelivery.address.city}},
              {{sub.curDelivery.address.street}} {{sub.curDelivery.address.houseno | houseNoPipe}}
            </mat-card-subtitle>
          </div>
          <div class="col-12 col-md-6">
            <mat-card-actions class="card-actions">
              <button mat-raised-button color="primary"
                      (click)="onNewComplaint(sub)" [innerHTML]="translationService.getTranslation('button_new_complaint')"></button>
            </mat-card-actions>
          </div>
        </div>

        <mat-card-content *ngIf="complaintsGrouped && complaintsGrouped.length > 0">
          <mat-accordion multi>
            <mat-expansion-panel *ngFor="let complaintGroup of complaintsGrouped; let innerI = index">
              <mat-expansion-panel-header [collapsedHeight]="'calc(100%)'" [expandedHeight]="'100%'" *ngIf="complaintGroup.at(0).subId === sub.backendId">
                <mat-panel-title class="complaintTitle">{{complaintGroup.at(0).reasonBaseData?.description}}</mat-panel-title>
                <mat-panel-description>
                  <ul style="margin-top: 1rem; margin-bottom: 1rem">
                    <li *ngFor="let complaint of complaintGroup">{{complaint.validDate.validFrom | date: translationService.getTranslation('dateFormat')}}</li>
                  </ul>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <mat-action-row>
                <!--
                <button mat-raised-button (click)="onDetails(innerI)"
                        *ngIf="complaint.state == 1">{{translationService.getTranslation('button_details')}}</button>
                <button mat-raised-button (click)="onDelete(innerI)"
                        *ngIf="complaint.state == -1" class="mr-3">{{translationService.getTranslation('button_delete')}}</button>
                <button mat-raised-button (click)="onEdit(innerI)"
                        *ngIf="complaint.state == -1">{{translationService.getTranslation('button_edit')}}</button>
                        -->
              </mat-action-row>
              <ng-template matExpansionPanelContent>
                <div class="row" *ngIf="complaintGroup.at(0).compensations && complaintGroup.at(0).compensations.length > 0">
                  <div class="col-6" [innerHTML]="translationService.getTranslation('complaint_compensation')"></div>
                  <div class="col-6">
                    <div *ngFor="let compensation of complaintGroup.at(0).compensations">
                      {{ compensation.description }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6" [innerHTML]="translationService.getTranslation('complaint_reasonMemo')"></div>
                  <div class="col-6">
                    {{complaintGroup.at(0).memo.memoText}}
                  </div>
                </div>
              </ng-template>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-card-content>
      </mat-card>
    </ng-container>
    </div>
  </div>
</div>
